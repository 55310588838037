export const setAuthToken = (token: string) => {
  localStorage.setItem('authToken', token);
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem('authToken');
};

export const removeAuthToken = () => {
  localStorage.removeItem('authToken');
};

export const setIsAuthenticated = (value: boolean) => {
  localStorage.setItem('isAuthenticated', value.toString());
};

export const isAuthenticated = async (): Promise<boolean> => {
  const token = getAuthToken();
  if (!token) return false;

  // server-side validation here?
  return true;
};
