import { useState, useEffect } from 'react';
import apiClient from '../../api/client';

interface Descriptions {
  dataDeliveryStates: Record<string, string>;
  dataDeliveryEvents: Record<string, string>;
  accountStates: Record<string, string>;
  accountStateEvents: Record<string, string>;
}

const DESCRIPTIONS_STORAGE_KEY = 'app_descriptions';

export function useDescriptions() {
  const [descriptions, setDescriptions] = useState<Descriptions | null>(null);

  useEffect(() => {
    const fetchDescriptions = async () => {
      const storedDescriptions = localStorage.getItem(DESCRIPTIONS_STORAGE_KEY);

      if (storedDescriptions) {
        setDescriptions(JSON.parse(storedDescriptions));
      } else {
        try {
          const [
            dataDeliveryStates,
            dataDeliveryEvents,
            accountStates,
            accountStateEvents
          ] = await Promise.all([
            apiClient.get('/admin/data_delivery_states'),
            apiClient.get('/admin/data_delivery_events'),
            apiClient.get('/admin/account_states'),
            apiClient.get('/admin/account_state_events')
          ]);

          const newDescriptions: Descriptions = {
            dataDeliveryStates: Object.fromEntries(dataDeliveryStates.data.map((state: any) => [state.state_name, state.description])),
            dataDeliveryEvents: Object.fromEntries(dataDeliveryEvents.data.map((event: any) => [event.event_name, event.description])),
            accountStates: Object.fromEntries(accountStates.data.map((state: any) => [state.state_name, state.description])),
            accountStateEvents: Object.fromEntries(accountStateEvents.data.map((event: any) => [event.event_name, event.description]))
          };

          localStorage.setItem(DESCRIPTIONS_STORAGE_KEY, JSON.stringify(newDescriptions));
          setDescriptions(newDescriptions);
        } catch (error) {
          console.error('Failed to fetch descriptions:', error);
        }
      }
    };

    fetchDescriptions();
  }, []);

  return descriptions;
}
