import axios from 'axios'
import { getAuthToken, removeAuthToken } from '../utils/auth';

export const getBaseURL = () => {
  if (import.meta.env.DEV) {
    return 'http://localhost:8000';  // Use localhost for local development
  }
  // For production, use the VITE_API_SERVICE_URL or fall back to the dev URL
  return import.meta.env.VITE_API_SERVICE_URL || 'https://api.workhelixapp.dev';
};

const apiClient = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      removeAuthToken();
      delete apiClient.defaults.headers.common['Authorization'];
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default apiClient;
