import { createRootRoute, createRoute, createRouter, Outlet, RouterProvider, useNavigate } from "@tanstack/react-router";
import Dashboard from "./pages/Dashboard";
import AccountDetails from "./pages/AccountDetails";
import AccountsList from "./pages/AccountsList";
import Datasets from "./pages/Datasets";
import Login from "./pages/Login";
import Navigation from "./components/Navigation";
import UserDetails from "./components/UserDetails";
import Users from "./pages/Users";
import { useState, useEffect, createContext, useContext } from "react";
import { isAuthenticated, removeAuthToken, getAuthToken } from "./utils/auth";
import apiClient from "./api/client";
import { Toaster } from "./components/ui/toaster";

export interface CustomRouteContext {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
}

export const RouteContext = createContext<CustomRouteContext | undefined>(undefined);

const AppLayout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const authenticated = await isAuthenticated();
        setIsLoggedIn(authenticated);
        if (authenticated) {
          const token = getAuthToken();
          if (token) {
            apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          }
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate({ to: '/login' });
    }
  }, [isLoggedIn, navigate]);

  const handleLogout = () => {
    removeAuthToken();
    delete apiClient.defaults.headers.common['Authorization'];
    setIsLoggedIn(false);
    navigate({ to: '/login' });
  };

  if (isLoggedIn === null) {
    return <div>Loading...</div>;
  }

  return (
    <RouteContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <div>
        <Navigation isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Outlet />
      </div>
    </RouteContext.Provider>
  );
};

interface ProtectedRouteProps {
  component: React.ComponentType;
}

const ProtectedRoute = ({ component: Component }: ProtectedRouteProps) => {
  const routeContext = useContext(RouteContext);
  const navigate = useNavigate();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();
      if (!authenticated) {
        routeContext?.setIsLoggedIn(false);
        navigate({ to: '/login' });
      } else {
        routeContext?.setIsLoggedIn(true);
      }
      setIsCheckingAuth(false);
    };
    checkAuth();
  }, [navigate, routeContext]);

  if (isCheckingAuth) {
    return <div>Checking authentication...</div>;
  }

  return routeContext?.isLoggedIn ? <Component /> : null;
};

export const rootRoute = createRootRoute({
  component: AppLayout,
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <ProtectedRoute component={Dashboard} />,
});

const accountsListRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/accounts",
  component: () => <ProtectedRoute component={AccountsList} />,
});

const accountDetailsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/accounts/$accountName",
  component: () => <ProtectedRoute component={AccountDetails} />,
});

const datasetsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/datasets",
  component: () => <ProtectedRoute component={Datasets} />,
});

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/login",
  component: Login,
});

const userDetailsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/users/$email",
  component: () => <ProtectedRoute component={UserDetails} />,
});

const usersRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/users",
  component: () => <ProtectedRoute component={Users} />,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  accountsListRoute,
  accountDetailsRoute,
  datasetsRoute,
  loginRoute,
  usersRoute,
  userDetailsRoute,
]);

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());

  useEffect(() => {
    const setupAuth = async () => {
      const authenticated = await isAuthenticated();
      if (authenticated) {
        const token = getAuthToken();
        if (token) {
          apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
      }
    };

    setupAuth();
  }, [isLoggedIn]);

  return (
    <>
      <RouterProvider
        router={router}
        context={{ isLoggedIn, setIsLoggedIn }}
      />
      <Toaster />
    </>
  );
}
