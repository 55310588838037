import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

const Datasets: React.FC = () => {
  return (
    <Card className="p-4">
      <CardHeader>
        <CardTitle>Dashboard</CardTitle>
      </CardHeader>
      <CardContent>
          <div>
            <h2 className="text-xl font-semibold mb-2">Work in progress...</h2>
          </div>
      </CardContent>
    </Card>
  );
};

export default Datasets;
