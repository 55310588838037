import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from "@/components/ui/alert";
import apiClient from '../api/client';
import { User, Users, Database, Truck, Server, Loader2 } from 'lucide-react';
import { useNavigate, Link } from '@tanstack/react-router';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import { RecentLoggedActionsTable } from '@/components/RecentLoggedActionsTable';

interface UserInfo {
  email: string;
  is_admin: boolean;
  account_name: string;
}

interface Delivery {
  id: string;
  account_name: string;
  created_at: string;
  status: string;
}

interface EnvironmentStatus {
  status: string | null;
  details: any;
}

const Dashboard: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [totalAccounts, setTotalAccounts] = useState<number | null>(null);
  const [totalUsers, setTotalUsers] = useState<number | null>(null);
  const [recentDeliveries, setRecentDeliveries] = useState<Delivery[] | null>(null);
  const [recentLoggedActions, setRecentLoggedActions] = useState<any[] | null>(null);
  const [userInfoLoading, setUserInfoLoading] = useState(true);
  const [accountCountLoading, setAccountCountLoading] = useState(true);
  const [userCountLoading, setUserCountLoading] = useState(true);
  const [deliveriesLoading, setDeliveriesLoading] = useState(true);
  const [loggedActionsLoading, setLoggedActionsLoading] = useState(true);
  const navigate = useNavigate();
  const [environmentStatuses, setEnvironmentStatuses] = useState<Record<string, EnvironmentStatus>>({
    dev: { status: null, details: null },
    prod: { status: null, details: null },
    whocc: { status: null, details: null },
  });

  const fetchWithQueue = useCallback((endpoint: string, setter: (data: any) => void, loadingSetter: (loading: boolean) => void) => {
    return new Promise<void>((resolve) => {
      requestAnimationFrame(async () => {
        try {
          const response = await apiClient.get(endpoint);
          if (typeof response.data === 'object' && 'count' in response.data) {
            setter(response.data.count);
          } else if (typeof response.data === 'number') {
            setter(response.data);
          } else {
            setter(response.data);
          }
        } catch (error) {
          console.error(`Error fetching ${endpoint}:`, error);
          setter(null);
        } finally {
          loadingSetter(false);
          resolve();
        }
      });
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setUserInfoLoading(true);
      setAccountCountLoading(true);
      setUserCountLoading(true);
      setDeliveriesLoading(true);
      setLoggedActionsLoading(true);

      await fetchWithQueue('/admin/dashboard/user_info', setUserInfo, setUserInfoLoading);
      await fetchWithQueue('/admin/dashboard/account_count', setTotalAccounts, setAccountCountLoading);
      await fetchWithQueue('/admin/dashboard/user_count', setTotalUsers, setUserCountLoading);
      await fetchWithQueue('/admin/dashboard/recent_deliveries', setRecentDeliveries, setDeliveriesLoading);
      await fetchWithQueue('/admin/dashboard/recent_logged_actions', setRecentLoggedActions, setLoggedActionsLoading);
    };

    fetchData();
  }, [fetchWithQueue]);

  useEffect(() => {
    const fetchEnvironmentStatus = async (url: string, env: string) => {
      try {
        const response = await apiClient.get(url);
        setEnvironmentStatuses(prev => ({
          ...prev,
          [env]: { status: 'Online', details: response.data }
        }));
      } catch (error) {
        setEnvironmentStatuses(prev => ({
          ...prev,
          [env]: { status: 'Offline', details: null }
        }));
      }
    };

    const fetchWHOCCStatus = async () => {
      try {
        const response = await fetch('https://workhelix--whocc-app-fastapi-app.modal.run/', {
          method: 'GET',
          mode: 'cors',
          credentials: 'omit',
        });
        if (response.ok) {
          const data = await response.json();
          setEnvironmentStatuses(prev => ({
            ...prev,
            whocc: {
              status: data.content?.status === 'UP' ? 'Online' : 'Offline',
              details: data
            }
          }));
        } else {
          throw new Error('Failed to fetch WHOCC status');
        }
      } catch (error) {
        console.error('Error fetching WHOCC status:', error);
        setEnvironmentStatuses(prev => ({
          ...prev,
          whocc: { status: 'Offline', details: null }
        }));
      }
    };

    fetchEnvironmentStatus('https://api.workhelixapp.dev/internal/ping', 'dev');
    fetchEnvironmentStatus('https://api.workhelixapp.com/internal/ping', 'prod');
    fetchWHOCCStatus();
  }, []);

  const DashboardCard: React.FC<{
    title: string;
    icon: React.ReactNode;
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
  }> = ({ title, icon, onClick, children, className }) => (
    <Card className={`hover:shadow-md transition-shadow cursor-pointer ${className}`} onClick={onClick}>
      <CardHeader className="flex flex-row items-center space-y-0 pb-2">
        {icon}
        <CardTitle className="text-lg font-medium ml-2">{title}</CardTitle>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );

  const EnvironmentStatusCard: React.FC = () => (
    <div className="space-y-4 h-full overflow-y-auto">
      {Object.entries(environmentStatuses).map(([env, status]) => (
        <div key={env} className="env-status">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className={`status-indicator w-2 h-2 rounded-full ${status.status === 'Online' ? 'bg-green-500' : 'bg-red-500'}`}></span>
              <span className="font-medium">{env.toUpperCase()}:</span>
              <span>{status.status || 'Loading...'}</span>
            </div>
            {status.status === 'Online' && status.details && (
              <Accordion type="single" collapsible className="w-auto">
                <AccordionItem value={`${env}-details`} className="border-none">
                  <AccordionTrigger className="py-0 px-2 text-sm">Details</AccordionTrigger>
                  <AccordionContent className="pt-1 pb-2 mt-1 bg-gray-50 rounded-md">
                    <div className="text-sm px-2 py-1">
                      {env === 'whocc' ? (
                        <>
                          <p><strong>Status:</strong> {status.details.content?.status || 'N/A'}</p>
                          <p><strong>Description:</strong> {status.details.content?.description || 'N/A'}</p>
                          <p><strong>Details:</strong> {Array.isArray(status.details.content?.details) ? status.details.content.details.join(', ') : 'N/A'}</p>
                        </>
                      ) : (
                        <>
                          <p><strong>App Version:</strong> {status.details.app_version || 'N/A'}</p>
                          <p><strong>Git SHA:</strong> {status.details.git_sha ? truncateGitSHA(status.details.git_sha) : 'N/A'}</p>
                        </>
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mx-auto p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <DashboardCard title="User Information" icon={<User className="h-5 w-5" />} onClick={() => navigate({ to: '/users/$email', params: { email: encodeURIComponent(userInfo?.email || '') } })}>
          {userInfoLoading ? (
            <div className="flex justify-center items-center h-20">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : userInfo ? (
            <>
              <p><strong>Email:</strong> {userInfo.email}</p>
              <p><strong>Admin:</strong> {userInfo.is_admin ? 'Yes' : 'No'}</p>
              <p><strong>Account:</strong> {userInfo.account_name}</p>
            </>
          ) : (
            <Alert variant="destructive">
              <AlertDescription>Failed to load user information.</AlertDescription>
            </Alert>
          )}
        </DashboardCard>

        <DashboardCard title="Account Statistics" icon={<Users className="h-5 w-5" />} onClick={() => navigate({ to: '/accounts' })}>
          {accountCountLoading || userCountLoading ? (
            <div className="flex justify-center items-center h-20">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <p><strong>Total Accounts:</strong> {totalAccounts !== null ? totalAccounts.toString() : "Loading..."}</p>
              <p><strong>Total Users:</strong> {totalUsers !== null ? totalUsers.toString() : "Loading..."}</p>
            </>
          )}
        </DashboardCard>

        <DashboardCard title="Recent Deliveries" icon={<Truck className="h-5 w-5" />}>
          {deliveriesLoading ? (
            <div className="flex justify-center items-center h-20">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : Array.isArray(recentDeliveries) ? (
            <div className="h-full overflow-y-auto pr-2">
              <ul className="space-y-2">
                {recentDeliveries.map((delivery) => (
                  <li key={delivery.id} className="text-sm">
                    <Link
                      to={`/accounts/${delivery.account_name}`}
                      className="text-foreground hover:underline"
                    >
                      <strong>{delivery.account_name}</strong> - {delivery.status} ({new Date(delivery.created_at).toLocaleDateString()})
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Alert variant="destructive">
              <AlertDescription>Failed to fetch recent deliveries.</AlertDescription>
            </Alert>
          )}
        </DashboardCard>
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        <Card className="md:col-span-1 h-[350px]">
          <CardHeader className="flex flex-row items-center space-y-0 pb-2">
            <Server className="h-5 w-5" />
            <CardTitle className="text-lg font-medium ml-2">System Status</CardTitle>
          </CardHeader>
          <CardContent className="h-[calc(100%-56px)] overflow-y-auto">
            <EnvironmentStatusCard />
          </CardContent>
        </Card>

        <Card className="md:col-span-2 h-[350px]">
          <CardHeader className="flex flex-row items-center space-y-0 pb-2">
            <Database className="h-5 w-5" />
            <CardTitle className="text-lg font-medium ml-2">Recent Logged Actions</CardTitle>
          </CardHeader>
          <CardContent className="h-[calc(100%-56px)] overflow-hidden">
            {loggedActionsLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader2 className="h-8 w-8 animate-spin" />
              </div>
            ) : recentLoggedActions ? (
              <RecentLoggedActionsTable actions={recentLoggedActions} isLoading={loggedActionsLoading} />
            ) : (
              <Alert variant="destructive">
                <AlertDescription>Failed to fetch recent logged actions.</AlertDescription>
              </Alert>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

function truncateGitSHA(sha: string, maxLength: number = 10): string {
  return sha.length > maxLength ? sha.substring(0, maxLength) + '...' : sha;
}

export default Dashboard;
