import apiClient from "./client";
import { setAuthToken, getAuthToken } from '../utils/auth';
import axios from 'axios';

export const login = async (username: string, password: string) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
        const response = await apiClient.post('/admin/login/submit', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.data.token) {
            setAuthToken(response.data.token);
            setupApiClient();
            return { success: true, data: { token: response.data.token } };
        } else {
            return { success: false, error: 'Invalid response from server' };
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return { success: false, error: error.response.data.detail || 'Login failed' };
        }
        return { success: false, error: 'An unexpected error occurred' };
    }
};

export const setupApiClient = () => {
    const token = getAuthToken();
    if (token) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
};
