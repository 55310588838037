import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from '@tanstack/react-router';
import LoginForm from '../components/LoginForm';
import { RouteContext } from '../App';
import { isAuthenticated, setIsAuthenticated } from '../utils/auth';
import { login, setupApiClient } from '../api/login';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Loader2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const Login: React.FC = () => {
  const routeContext = useContext(RouteContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isAuthenticated();
      if (authenticated) {
        routeContext?.setIsLoggedIn(true);
        navigate({ to: '/' });
      } else {
        routeContext?.setIsLoggedIn(false);
      }
      setIsCheckingAuth(false);
    };
    checkAuth();
  }, [routeContext, navigate]);

  const handleLogin = useCallback(async (username: string, password: string) => {
    setError(null);
    try {
      const result = await login(username, password);
      if (result.success) {
        setupApiClient();
        setIsAuthenticated(true);
        routeContext?.setIsLoggedIn(true);

        toast({
          title: "Login Successful",
          description: "You have been successfully logged in.",
          duration: 3000,
        });

        // Delay navigation to allow toast to be visible
        setTimeout(() => {
          navigate({ to: '/' });
        }, 1000);
      } else {
        throw new Error(result.error || 'Login failed');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      setError(errorMessage);
      setIsAuthenticated(false);
      routeContext?.setIsLoggedIn(false);

      toast({
        title: "Login Error",
        description: errorMessage,
        variant: "destructive",
        duration: 5000,
      });
    }
  }, [routeContext, navigate, toast]);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  if (isCheckingAuth) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex justify-center bg-background pt-16 px-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Login</CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4 border-2 border-red-500">
              <AlertTitle className="text-red-500 font-bold">Error</AlertTitle>
              <AlertDescription className="text-red-700">{error}</AlertDescription>
            </Alert>
          )}
          <LoginForm onSubmit={handleLogin} clearError={clearError} />
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
