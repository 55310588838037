import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useToast } from "@/components/ui/use-toast";
import apiClient from '../api/client';

interface Company {
  company_id: string;
  company_name: string;
  cloned_from: string;
  display_headcount: number | null;
}

interface FindCompaniesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FindCompaniesModal: React.FC<FindCompaniesModalProps> = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Company[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;
    setIsLoading(true);
    try {
      const response = await apiClient.get(`/admin/find_companies?query_string=${searchQuery}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Failed to fetch companies:', error);
      setSearchResults([]);
      toast({
        title: "Error",
        description: "Failed to fetch companies. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied",
      description: "Company ID copied to clipboard.",
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleCloseModal = () => {
    setSearchQuery('');
    setSearchResults(null);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setSearchQuery('');
      setSearchResults(null);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseModal}>
      <DialogContent className="sm:max-w-[625px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Find Companies</DialogTitle>
        </DialogHeader>
        <div className="flex space-x-2 mb-4">
          <Input
            placeholder="Search companies..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Button onClick={handleSearch} disabled={isLoading}>
            {isLoading ? 'Searching...' : 'Search'}
          </Button>
        </div>
        {searchResults === null ? (
          <p>Enter a search query and press Enter or click Search.</p>
        ) : searchResults.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Company Name</TableHead>
                <TableHead>Headcount</TableHead>
                <TableHead>ID</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {searchResults.map((company) => (
                <TableRow key={company.company_id}>
                  <TableCell>{company.company_name}</TableCell>
                  <TableCell>
                    {company.display_headcount !== null
                      ? company.display_headcount.toLocaleString(undefined, { maximumFractionDigits: 0 })
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{company.cloned_from.split(':')[1]}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleCopy(company.cloned_from.split(':')[1])}>
                      Copy ID
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>No results found.</p>
        )}
      </DialogContent>
    </Dialog>
  );
};
