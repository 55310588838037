import React, { useEffect, useState, useCallback } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Link } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from "@/components/ui/use-toast";
import apiClient from '../api/client';
import { FindCompaniesModal } from '../components/FindCompaniesModal';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { BarChart2, Loader2, Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useDescriptions } from '../components/hooks/useDescriptions';

interface Account {
  account_name: string;
  account_type: string;
  current_state: string;
  created_at: string;
}

const AccountsList: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [rcidToClone, setRcidToClone] = useState('');
  const [accountType, setAccountType] = useState<'deepdive' | 'springboard'>('deepdive');
  const [isCreating, setIsCreating] = useState(false);
  const [isFindCompaniesModalOpen, setIsFindCompaniesModalOpen] = useState(false);
  const { toast } = useToast();
  const descriptions = useDescriptions();

  const fetchAccounts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiClient.get('/admin/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
      toast({
        title: "Error",
        description: "Failed to fetch accounts. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  const accountSummary = React.useMemo(() => {
    const summary = {
      total: accounts.length,
      deepdive: 0,
      springboard: 0,
      byState: {} as Record<string, number>
    };

    accounts.forEach(account => {
      if (account.account_type === 'deepdive') summary.deepdive++;
      if (account.account_type === 'springboard') summary.springboard++;

      summary.byState[account.current_state] = (summary.byState[account.current_state] || 0) + 1;
    });

    return summary;
  }, [accounts]);

  const accountStateData = React.useMemo(() => {
    return Object.entries(accountSummary.byState).map(([name, value]) => ({ name, value }));
  }, [accountSummary.byState]);

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#a4de6c'];

  const handleCreateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsCreating(true);
    try {
      const response = await apiClient.post('/admin/accounts/create', null, {
        params: {
          account_name: accountName,
          rcid_to_clone: rcidToClone,
          account_type: accountType,
        }
      });

      toast({
        title: "Account Created",
        description: `Successfully created account: ${response.data.account_name}`,
      });

      // Reset form fields
      setAccountName('');
      setRcidToClone('');
      setAccountType('deepdive');

      // Refresh the account list
      await fetchAccounts();

      // Close the modal after a short delay
      setTimeout(() => {
        setIsCreateModalOpen(false);
      }, 1000);
    } catch (error: any) {
      console.error('Failed to create account:', error);
      let errorMessage = 'Failed to create account: ';

      if (error.response) {
        if (error.response.status === 401) {
          errorMessage += 'Authentication failed. Please log in again.';
        } else if (error.response.status === 500) {
          errorMessage += 'Internal server error. Please try again later or contact support.';
        } else {
          errorMessage += error.response.data?.detail || error.message || 'Unknown error';
        }
      } else if (error.request) {
        errorMessage += 'No response received from the server. Please try again.';
      } else {
        errorMessage += 'An unexpected error occurred. Please try again.';
      }

      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });

      // Close the modal on error as well
      setIsCreateModalOpen(false);
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <div className="container mx-auto p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-6">Accounts</h1>
      <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-2">
        <Card className="md:col-span-1 lg:col-span-1 flex flex-col">
          <CardHeader>
            <CardTitle>Account Summary & Actions</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col flex-grow">
            <div className="space-y-2 mb-4">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="flex items-center cursor-help">
                      <strong>Total Accounts:</strong>{" "}
                      {accountSummary.total}
                      <Info className="ml-2 h-4 w-4 text-muted-foreground" />
                    </p>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Includes 1 legacy and 1 internal account in addition to Deepdive and Springboard accounts.</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <p><strong>Deepdive Accounts:</strong> {accountSummary.deepdive}</p>
              <p><strong>Springboard Accounts:</strong> {accountSummary.springboard}</p>
            </div>
            <div className="mt-auto space-y-2">
              <Button className="w-full" variant="outline" onClick={() => setIsFindCompaniesModalOpen(true)}>
                Find Companies (RCID)
              </Button>
              <Dialog open={isCreateModalOpen} onOpenChange={setIsCreateModalOpen}>
                <DialogTrigger asChild>
                  <Button className="w-full" variant="default">Create Account</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Create New Account</DialogTitle>
                  </DialogHeader>
                  <form onSubmit={handleCreateAccount} className="space-y-4">
                    <div>
                      <Label htmlFor="accountName">Account Name</Label>
                      <Input
                        id="accountName"
                        value={accountName}
                        onChange={(e) => setAccountName(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <Label htmlFor="rcidToClone">RCID to Clone</Label>
                      <Input
                        id="rcidToClone"
                        type="number"
                        value={rcidToClone}
                        onChange={(e) => setRcidToClone(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <Label htmlFor="accountType">Account Type</Label>
                      <Select value={accountType} onValueChange={(value: 'deepdive' | 'springboard') => setAccountType(value)}>
                        <SelectTrigger>
                          <SelectValue placeholder="Select account type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="deepdive">Deepdive</SelectItem>
                          <SelectItem value="springboard">Springboard</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex justify-end">
                      <Button type="submit" disabled={isCreating}>
                        {isCreating ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Creating...
                          </>
                        ) : (
                          'Create Account'
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </CardContent>
        </Card>

        <Card className="md:col-span-1 lg:col-span-1">
          <CardHeader>
            <CardTitle className="flex items-center">
              <BarChart2 className="mr-2 h-5 w-5" />
              Accounts by State
            </CardTitle>
          </CardHeader>
          <CardContent>
            {accountStateData.length > 0 ? (
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={accountStateData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name }) => name}
                  >
                    {accountStateData.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <p>No data available</p>
            )}
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Accounts List</CardTitle>
        </CardHeader>
        <CardContent>
          {loading ? (
            <Skeleton className="w-full h-24" />
          ) : (
            <div className="table-container">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Account Name</TableHead>
                    <TableHead>Account Type</TableHead>
                    <TableHead>Current State</TableHead>
                    <TableHead>Created At</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {accounts.map((account) => (
                    <TableRow
                      key={account.account_name}
                      className="hover:bg-muted/50 transition-colors"
                    >
                      <TableCell>
                        <Link
                          to="/accounts/$accountName"
                          params={{ accountName: account.account_name }}
                          className="text-primary hover:underline"
                        >
                          {account.account_name}
                        </Link>
                      </TableCell>
                      <TableCell>{account.account_type}</TableCell>
                      <TableCell>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="cursor-help">
                              <span className="border-b border-dotted border-gray-400">
                                {account.current_state}
                              </span>
                            </TooltipTrigger>
                            <TooltipContent side="top" className="max-w-xs">
                              <p className="text-sm">
                                {descriptions?.accountStates[account.current_state] || 'No description available'}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableCell>
                      <TableCell>{new Date(account.created_at).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      <FindCompaniesModal
        isOpen={isFindCompaniesModalOpen}
        onClose={() => setIsFindCompaniesModalOpen(false)}
      />
    </div>
  );
};

export default AccountsList;
