import React, { useEffect, useState, useCallback } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useParams } from '@tanstack/react-router';
import apiClient from '../api/client';
import { DataDeliveriesTable, DataDelivery } from '../components/DataDeliveriesTable';
import { UsersList } from '../components/UsersList';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Loader2, Upload, Users, Database, ArrowRight, Info } from 'lucide-react';
import { AccountStateChange } from '../components/AccountStateChange';
import { useToast } from "@/components/ui/use-toast";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useDescriptions } from '../components/hooks/useDescriptions';

interface User {
  email: string;
  account_name: string;
  created_at: string;
  updated_at: string;
}

interface Account {
  account_name: string;
  account_type: string;
  created_at: string;
  current_state: string;
  default_company_id: string;
  last_event: string;
  tenant_schema: string;
  updated_at: string;
  data_deliveries: DataDelivery[];
  users: User[];
}

interface ValidEvent {
  event: string;
  account_type: string;
  from_state: string;
  to_state: string;
  from_state_description: string;
  to_state_description: string;
  event_description: string;
}

const AccountDetails: React.FC = () => {
  const { accountName } = useParams({ from: '/accounts/$accountName' });
  const [account, setAccount] = useState<Account | null>(null);
  const [deliveries, setDeliveries] = useState<DataDelivery[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fileType, setFileType] = useState<'csv' | 'tsv'>('tsv');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [validEvents, setValidEvents] = useState<ValidEvent[]>([]);
  const { toast } = useToast();
  const descriptions = useDescriptions();

  const handleValidEventsReceived = (events: ValidEvent[]) => {
    setValidEvents(events);
  };

  const getUniqueStates = () => {
    const states = new Set<string>();
    validEvents.forEach(event => {
      states.add(event.from_state);
      states.add(event.to_state);
    });
    return Array.from(states);
  };

  const isValidTransition = (fromState: string, toState: string) => {
    return validEvents.some(event => event.from_state === fromState && event.to_state === toState);
  };

  const fetchAccount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/admin/accounts/${accountName}`);
      setAccount(response.data);
      setDeliveries(response.data.data_deliveries || []);
    } catch (error) {
      console.error('Error fetching account details:', error);
      setError('Failed to fetch account details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [accountName]);

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  const handleUpdateDelivery = useCallback((updatedDelivery: DataDelivery) => {
    setDeliveries(prevDeliveries =>
      prevDeliveries.map(d =>
        d.delivery_id === updatedDelivery.delivery_id ? { ...d, ...updatedDelivery } : d
      )
    );
    // Fetch the updated account information when a delivery is matched or approved
    if (updatedDelivery.current_state === 'matched' || updatedDelivery.current_state === 'live') {
      fetchAccount();
    }
  }, [fetchAccount]);

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast({
        title: "Error",
        description: "No file selected",
        variant: "destructive",
      });
      return;
    }

    const formData = new FormData();
    formData.append('data', selectedFile);

    try {
      setUploading(true);
      const response = await apiClient.post(
        `/admin/accounts/${accountName}/load_file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: { file_type: fileType },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "File uploaded successfully",
        });
        // Fetch the updated account information after successful upload
        await fetchAccount();
      }
    } catch (error: any) {
      console.error('Error uploading file:', error);
      toast({
        title: "Error",
        description: error.response?.data?.detail || "Failed to upload file",
        variant: "destructive",
      });
    } finally {
      setUploading(false);
      setSelectedFile(null);
    }
  };

  const handleUserAdded = (newUser: User) => {
    setAccount((prevAccount) => {
      if (!prevAccount) return null;
      return {
        ...prevAccount,
        users: [...prevAccount.users, newUser],
      };
    });
  };

  return (
    <div className="container mx-auto p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-6">Account Details: {accountName}</h1>

      {loading ? (
        <Skeleton className="w-full h-24" />
      ) : error ? (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : account ? (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          <Card>
            <CardHeader>
              <CardTitle>Account Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="font-medium">Account Type</TableCell>
                    <TableCell>{account.account_type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">Current State</TableCell>
                    <TableCell>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <span className="flex items-center cursor-help">
                              {account.current_state}
                              <Info className="ml-2 h-4 w-4 text-gray-400" />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{descriptions?.accountStates[account.current_state] || 'No description available'}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">Last Event</TableCell>
                    <TableCell>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <span className="flex items-center cursor-help">
                              {account.last_event}
                              <Info className="ml-2 h-4 w-4 text-gray-400" />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{descriptions?.accountStateEvents[account.last_event] || 'No description available'}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">Created At</TableCell>
                    <TableCell>{new Date(account.created_at).toLocaleString()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">Updated At</TableCell>
                    <TableCell>{new Date(account.updated_at).toLocaleString()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Account State</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col items-center space-y-4">
                <div className="text-2xl font-bold">{account.current_state}</div>
                <div className="flex flex-wrap justify-center items-center gap-2">
                  {getUniqueStates().map((state, index) => (
                    <React.Fragment key={state}>
                      {index > 0 && <ArrowRight className="text-muted-foreground" />}
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div
                              className={`px-3 py-1 rounded-full text-sm cursor-help ${
                                state === account.current_state
                                  ? 'bg-primary text-primary-foreground'
                                  : isValidTransition(account.current_state, state)
                                    ? 'bg-secondary text-secondary-foreground'
                                    : 'bg-muted text-muted-foreground'
                              }`}
                            >
                              {state}
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{descriptions?.accountStates[state] || 'No description available'}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </React.Fragment>
                  ))}
                </div>
                <AccountStateChange
                  accountName={account.account_name}
                  currentState={account.current_state}
                  onStateChange={fetchAccount}
                  onValidEventsReceived={handleValidEventsReceived}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Upload Data</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <label htmlFor="file-type" className="text-sm font-medium">File Type</label>
                  <Select value={fileType} onValueChange={(value: 'tsv' | 'csv') => setFileType(value)}>
                    <SelectTrigger id="file-type">
                      <SelectValue placeholder="Select file type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="tsv">TSV</SelectItem>
                      <SelectItem value="csv">CSV</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <label htmlFor="file-upload" className="text-sm font-medium">Select File</label>
                  <Input
                    id="file-upload"
                    type="file"
                    accept={`.${fileType}`}
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      setSelectedFile(file || null);
                    }}
                  />
                </div>
                <Button onClick={handleFileUpload} disabled={!selectedFile || uploading} className="w-full">
                  {uploading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Uploading...
                    </>
                  ) : (
                    <>
                      <Upload className="mr-2 h-4 w-4" />
                      Upload
                    </>
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Alert>
          <AlertTitle>No Data</AlertTitle>
          <AlertDescription>No account data available.</AlertDescription>
        </Alert>
      )}

      {account && (
        <>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Database className="mr-2 h-5 w-5" />
                Data Deliveries
              </CardTitle>
            </CardHeader>
            <CardContent>
              <DataDeliveriesTable
                deliveries={deliveries}
                accountName={accountName}
                onUpdateDelivery={handleUpdateDelivery}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Users className="mr-2 h-5 w-5" />
                Users
              </CardTitle>
            </CardHeader>
            <CardContent>
              <UsersList
                users={account.users}
                accountName={account.account_name}
                onUserAdded={handleUserAdded}
              />
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default AccountDetails;
