import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import apiClient from '../api/client';
import { CheckCircle, XCircle, RefreshCw, ChevronUp, ChevronDown, Info } from 'lucide-react';
import { Card, CardContent } from "@/components/ui/card";
import { useDescriptions } from '../components/hooks/useDescriptions';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { saveAs } from 'file-saver';

interface DataDelivery {
  delivery_id: string;
  account_name: string;
  created_at: string;
  current_state: 'new' | 'loaded' | 'parsed' | 'in_matching' | 'matched' | 'live' | 'load_failed' | 'parse_failed' | 'match_failed' | 'missing_raw_data';
  data_delivery_type: string;
  details: {
    md5: string;
    msg: string;
    path: string;
  };
  last_event: string;
  raw_record_count: number;
  parsed_record_count: number;
  matched_record_count: number;
  updated_at: string;
  column_mapping: any;
}

interface DataDeliveriesTableProps {
  deliveries: DataDelivery[];
  accountName: string;
  onUpdateDelivery: (updatedDelivery: DataDelivery) => void;
}

interface DeliveryStats {
  detailed_stats: {
    record_count: number;
    record_count_with_null_description: number;
    fte_with_null_description: number;
    pct_records_with_null_description: number;
    pct_fte_with_null_description: number;
    average_job_description_character_count: number;
  };
  unmatched_stats: {
    records_unmatched: number;
    fte_unmatched: number;
  };
}

export const DataDeliveriesTable: React.FC<DataDeliveriesTableProps> = ({
  deliveries: initialDeliveries,
  accountName,
  onUpdateDelivery
}) => {
  const [deliveries, setDeliveries] = useState(initialDeliveries);

  useEffect(() => {
    setDeliveries(initialDeliveries);
  }, [initialDeliveries]);

  const deliveriesRef = useRef(deliveries);
  const [expandedRows, setExpandedRows] = useState(new Set<string>());
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const [roleInstancesCounts, setRoleInstancesCounts] = useState<Record<string, number>>({});
  const { toast } = useToast();
  const descriptions = useDescriptions();
  const [deliveryStats, setDeliveryStats] = useState<Record<string, DeliveryStats>>({});

  useEffect(() => {
    deliveriesRef.current = deliveries;
  }, [deliveries]);

  useEffect(() => {
    const pollInterval = setInterval(() => {
      deliveriesRef.current.forEach(delivery => {
        if (delivery.current_state === 'in_matching' && delivery.delivery_id) {
          fetchDeliveryStatus(delivery.delivery_id);
        }
      });
    }, 5000);

    return () => clearInterval(pollInterval);
  }, [accountName]);

  useEffect(() => {
    deliveries.forEach(delivery => {
      if (['matched', 'live'].includes(delivery.current_state)) {
        fetchRoleInstancesCount(delivery.delivery_id);
      }
    });
  }, [deliveries]);

  useEffect(() => {
    deliveries.forEach(delivery => {
      fetchDeliveryStats(delivery.delivery_id);
    });
  }, [deliveries]);

  const fetchDeliveryStatus = async (deliveryId: string) => {
    try {
      const response = await apiClient.get(`/admin/account/${accountName}/delivery/${deliveryId}`);
      const updatedDelivery = response.data;
      updateDelivery(updatedDelivery);

      // If the status has changed to 'matched' or the last_event is 'matching_completed',
      // fetch the updated stats
      if (updatedDelivery.current_state === 'matched' || updatedDelivery.last_event === 'matching_completed') {
        await fetchDeliveryStats(deliveryId);
        await fetchRoleInstancesCount(deliveryId);
      }
    } catch (error) {
      console.error('Error fetching delivery status:', error);
      toast({
        title: "Error",
        description: "Failed to fetch delivery status. Please try again.",
        variant: "destructive",
      });
    }
  };

  const fetchRoleInstancesCount = async (deliveryId: string) => {
    try {
      const response = await apiClient.get(`/admin/account/${accountName}/delivery/${deliveryId}/role_instances_count`);
      setRoleInstancesCounts(prev => ({ ...prev, [deliveryId]: response.data }));
    } catch (error) {
      console.error('Error fetching role instances count:', error);
      // Set count to 0 or null in case of error
      setRoleInstancesCounts(prev => ({ ...prev, [deliveryId]: 0 }));
    }
  };

  const fetchDeliveryStats = async (deliveryId: string) => {
    try {
      const response = await apiClient.get(`/admin/account/${accountName}/delivery/${deliveryId}/stats`);
      setDeliveryStats(prev => ({ ...prev, [deliveryId]: response.data }));
    } catch (error) {
      console.error('Error fetching delivery stats:', error);
      toast({
        title: "Error",
        description: "Failed to fetch delivery stats. Please try again.",
        variant: "destructive",
      });
    }
  };

  const updateDelivery = (updatedDelivery: DataDelivery) => {
    setDeliveries(prevDeliveries =>
      prevDeliveries.map(d =>
        d.delivery_id === updatedDelivery.delivery_id ? { ...d, ...updatedDelivery } : d
      )
    );
    onUpdateDelivery(updatedDelivery);
  };

  const handleAction = async (deliveryId: string, action: string) => {
    setIsLoading(prev => ({ ...prev, [deliveryId]: true }));
    try {
      // Normalize the action for API calls
      const apiAction = action.startsWith('re') ? action.slice(2) : action;

      const response = await apiClient.put(`/admin/account/${accountName}/delivery/${deliveryId}/${apiAction}`);
      if (response.status === 200) {
        toast({
          title: "Success",
          description: `Action ${action} initiated`,
        });
        updateDelivery(response.data);
        // Immediately fetch the latest status after the action
        await fetchDeliveryStatus(deliveryId);
      } else {
        throw new Error(response.data.detail || 'Unknown error occurred');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to perform action: ${error}`,
        variant: "destructive",
      });
    } finally {
      setIsLoading(prev => ({ ...prev, [deliveryId]: false }));
    }
  };

  const handleBuildRoleInstances = async (deliveryId: string) => {
    setIsLoading(prev => ({ ...prev, [deliveryId]: true }));
    try {
        const response = await apiClient.post(`/admin/account/${accountName}/delivery/${deliveryId}/build_role_instances`);
        if (response.status === 200) {
            toast({
                title: "Success",
                description: "Role instances built successfully",
            });
            updateDelivery(response.data);
        }
    } catch (error) {
        console.error('Error building role instances:', error);
        toast({
            title: "Error",
            description: "Failed to build role instances. Please try again.",
            variant: "destructive",
        });
    } finally {
        setIsLoading(prev => ({ ...prev, [deliveryId]: false }));
    }
};

const handleApproveDelivery = async (deliveryId: string) => {
  setIsLoading(prev => ({ ...prev, [deliveryId]: true }));
  try {
    const response = await apiClient.put(`/admin/account/${accountName}/delivery/${deliveryId}/approve`);
    if (response.status === 200) {
      toast({
        title: "Success",
        description: "Delivery approved successfully",
      });
      const updatedDelivery = response.data;
      updateDelivery(updatedDelivery);
      onUpdateDelivery(updatedDelivery);  // Notify parent component
    }
  } catch (error) {
    console.error('Error approving delivery:', error);
    toast({
      title: "Error",
      description: "Failed to approve delivery. Please try again.",
      variant: "destructive",
    });
  } finally {
    setIsLoading(prev => ({ ...prev, [deliveryId]: false }));
  }
};

const handleGenerateQASpreadsheet = async (deliveryId: string) => {
  setIsLoading(prev => ({ ...prev, [deliveryId]: true }));
  try {
    const response = await apiClient.get(
      `/admin/account/${accountName}/delivery/${deliveryId}/qa_spreadsheet`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Get the filename from the X-Filename header
    const filename = response.headers['x-filename'] || `QA_Spreadsheet_${accountName}_${deliveryId}.xlsx`;

    saveAs(blob, filename);
    toast({
      title: "Success",
      description: "QA Spreadsheet generated and downloaded successfully",
    });
  } catch (error) {
    console.error('Error generating QA spreadsheet:', error);
    toast({
      title: "Error",
      description: "Failed to generate QA spreadsheet. Please try again.",
      variant: "destructive",
    });
  } finally {
    setIsLoading(prev => ({ ...prev, [deliveryId]: false }));
  }
};

const getActionButton = (delivery: DataDelivery) => {
  switch (delivery.current_state) {
    case 'new':
    case 'loaded':
    case 'parse_failed':
      return (
        <Button
          variant="outline"
          size="sm"
          onClick={() => handleAction(delivery.delivery_id, 'parse')}
          disabled={isLoading[delivery.delivery_id]}
        >
          {isLoading[delivery.delivery_id] ? 'Parsing...' : 'Parse'}
        </Button>
      );
    case 'parsed':
      return (
        <Button
          variant="outline"
          size="sm"
          onClick={() => handleAction(delivery.delivery_id, 'match')}
          disabled={isLoading[delivery.delivery_id]}
        >
          {isLoading[delivery.delivery_id] ? 'Matching...' : 'Match'}
        </Button>
      );
    case 'match_failed':
      return (
        <Button
          variant="outline"
          size="sm"
          onClick={() => handleAction(delivery.delivery_id, 'match')}
          disabled={isLoading[delivery.delivery_id]}
        >
          {isLoading[delivery.delivery_id] ? 'Matching...' : 'Rematch'}
        </Button>
      );
    case 'matched':
      if (delivery.last_event === 'matching_completed') {
        if (roleInstancesCounts[delivery.delivery_id] === 0) {
          return (
            <Button
              variant="outline"
              size="sm"
              onClick={() => handleBuildRoleInstances(delivery.delivery_id)}
              disabled={isLoading[delivery.delivery_id]}
            >
              {isLoading[delivery.delivery_id] ? 'Building...' : 'Build Role Instances'}
            </Button>
          );
        } else {
          return (
            <>
              <Button
                variant="outline"
                size="sm"
                onClick={() => handleApproveDelivery(delivery.delivery_id)}
                disabled={isLoading[delivery.delivery_id]}
              >
                {isLoading[delivery.delivery_id] ? 'Approving...' : 'Approve'}
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => handleGenerateQASpreadsheet(delivery.delivery_id)}
                disabled={isLoading[delivery.delivery_id]}
              >
                {isLoading[delivery.delivery_id] ? 'Generating...' : 'Generate QA Spreadsheet'}
              </Button>
            </>
          );
        }
      }
      return null;
    case 'live':
      return (
        <Button
          variant="outline"
          size="sm"
          onClick={() => handleGenerateQASpreadsheet(delivery.delivery_id)}
          disabled={isLoading[delivery.delivery_id]}
        >
          {isLoading[delivery.delivery_id] ? 'Generating...' : 'Generate QA Spreadsheet'}
        </Button>
      );
    default:
      return null;
  }
};

const getStateIcon = (state: string) => {
  switch (state) {
    case 'new':
    case 'loaded':
    case 'parsed':
      return <RefreshCw className="text-blue-500" />;
    case 'matched':
    case 'live':
      return <CheckCircle className="text-green-500" />;
    case 'load_failed':
    case 'parse_failed':
    case 'match_failed':
      return <XCircle className="text-red-500" />;
    case 'in_matching':
      return <RefreshCw className="text-blue-500 animate-spin" />;
    case 'missing_raw_data':
      return <XCircle className="text-red-500" />;
    default:
      return null;
  }
};

const toggleExpandRow = (deliveryId: string) => {
  setExpandedRows(prev => {
    const newSet = new Set(prev);
    if (newSet.has(deliveryId)) {
      newSet.delete(deliveryId);
    } else {
      newSet.add(deliveryId);
    }
    return newSet;
  });
};

const renderDetails = (delivery: DataDelivery) => (
  <Card className="bg-gray-50">
    <CardContent className="p-4">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h4 className="font-semibold mb-2">Data</h4>
          <p className="text-sm"><span className="font-semibold">Data Delivery Type:</span> {delivery.data_delivery_type}</p>
          <p className="text-sm"><span className="font-semibold">Created At:</span> {new Date(delivery.created_at).toLocaleString()}</p>
          <p className="text-sm"><span className="font-semibold">Updated At:</span> {new Date(delivery.updated_at).toLocaleString()}</p>

          <h4 className="font-semibold mt-4 mb-2">Record Counts</h4>
          <p className="text-sm">Raw: {delivery.raw_record_count}</p>
          <p className="text-sm">Parsed: {delivery.parsed_record_count}</p>
          <p className="text-sm">Matched: {delivery.matched_record_count}</p>
          {['matched', 'live'].includes(delivery.current_state) && (
            <p className="text-sm">Role Instances: {roleInstancesCounts[delivery.delivery_id] ?? 'Loading...'}</p>
          )}
        </div>

        {deliveryStats[delivery.delivery_id] && (
          <div>
            <h4 className="font-semibold mb-2">Detailed Statistics</h4>
            <div className="space-y-2 mb-4">
              <p className="text-sm">Records with null description: {deliveryStats[delivery.delivery_id].detailed_stats.record_count_with_null_description}</p>
              <p className="text-sm">FTE with null description: {deliveryStats[delivery.delivery_id].detailed_stats.fte_with_null_description.toFixed(2)}</p>
              <p className="text-sm">Avg. job description length: {
                typeof deliveryStats[delivery.delivery_id].detailed_stats.average_job_description_character_count === 'number'
                  ? `${deliveryStats[delivery.delivery_id].detailed_stats.average_job_description_character_count.toFixed(2)} characters`
                  : 'N/A'
              }</p>
            </div>
            <div className="flex justify-center items-center space-x-8">
              <div className="flex flex-col items-center">
                <div className="w-24 h-24">
                  <CircularProgressbar
                    value={deliveryStats[delivery.delivery_id].detailed_stats.pct_records_with_null_description}
                    text={`${deliveryStats[delivery.delivery_id].detailed_stats.pct_records_with_null_description.toFixed(1)}%`}
                    styles={buildStyles({
                      textSize: '22px',
                      pathColor: `rgba(62, 152, 199, ${deliveryStats[delivery.delivery_id].detailed_stats.pct_records_with_null_description / 100})`,
                      textColor: '#3e98c7',
                      trailColor: '#d6d6d6',
                    })}
                  />
                </div>
                <p className="text-xs text-center mt-1">Records with null description</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="w-24 h-24">
                  <CircularProgressbar
                    value={deliveryStats[delivery.delivery_id].detailed_stats.pct_fte_with_null_description}
                    text={`${deliveryStats[delivery.delivery_id].detailed_stats.pct_fte_with_null_description.toFixed(1)}%`}
                    styles={buildStyles({
                      textSize: '22px',
                      pathColor: `rgba(62, 152, 199, ${deliveryStats[delivery.delivery_id].detailed_stats.pct_fte_with_null_description / 100})`,
                      textColor: '#3e98c7',
                      trailColor: '#d6d6d6',
                    })}
                  />
                </div>
                <p className="text-xs text-center mt-1">FTE with null description</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </CardContent>
  </Card>
);

const renderStateWithDescription = (state: string, description?: string) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <span className="flex items-center cursor-help">
          {getStateIcon(state)} {state}
          {description && <Info className="ml-1 h-4 w-4 text-gray-400" />}
        </span>
      </TooltipTrigger>
      {description && (
        <TooltipContent>
          <p>{description}</p>
        </TooltipContent>
      )}
    </Tooltip>
  </TooltipProvider>
);

const renderEventWithDescription = (event: string, description?: string) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <span className="flex items-center cursor-help">
          {event}
          {description && <Info className="ml-1 h-4 w-4 text-gray-400" />}
        </span>
      </TooltipTrigger>
      {description && (
        <TooltipContent>
          <p>{description}</p>
        </TooltipContent>
      )}
    </Tooltip>
  </TooltipProvider>
);

const getRowClassName = (delivery: DataDelivery) => {
  if (delivery.current_state === 'live' && roleInstancesCounts[delivery.delivery_id] === 0) {
    return 'opacity-50 bg-gray-100';
  }
  if (roleInstancesCounts[delivery.delivery_id] > 0) {
    return 'bg-green-50 border-l-4 border-green-500';
  }
  return '';
};

if (deliveries.length === 0) {
  return <p>No data deliveries available.</p>;
}

return (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Delivery ID</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Last Event</TableHead>
        <TableHead>Actions</TableHead>
        <TableHead>Delivery KPIs</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {deliveries.map((delivery) => (
        <React.Fragment key={delivery.delivery_id}>
          <TableRow className={getRowClassName(delivery)}>
            <TableCell>{delivery.delivery_id}</TableCell>
            <TableCell>
              {renderStateWithDescription(delivery.current_state, descriptions?.dataDeliveryStates[delivery.current_state])}
            </TableCell>
            <TableCell>
              {renderEventWithDescription(delivery.last_event, descriptions?.dataDeliveryEvents[delivery.last_event])}
            </TableCell>
            <TableCell>
              <div className="space-x-2">
                {getActionButton(delivery)}
                <Button variant="ghost" onClick={() => toggleExpandRow(delivery.delivery_id)}>
                  {expandedRows.has(delivery.delivery_id) ? <ChevronUp /> : <ChevronDown />}
                </Button>
              </div>
            </TableCell>
            <TableCell>
              <div className="space-y-1">
                <p>HRIS records: {delivery.parsed_record_count}</p>
                <p>Records unmatched: {deliveryStats[delivery.delivery_id]?.unmatched_stats.records_unmatched ?? 'Loading...'}</p>
                <p>FTE unmatched: {deliveryStats[delivery.delivery_id]?.unmatched_stats.fte_unmatched?.toFixed(2) ?? 'Loading...'}</p>
              </div>
            </TableCell>
          </TableRow>
          {expandedRows.has(delivery.delivery_id) && (
            <TableRow>
              <TableCell colSpan={5}>
                {renderDetails(delivery)}
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
);
};

export type { DataDelivery };
