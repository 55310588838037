import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { ChevronDown, ChevronUp, Loader2 } from 'lucide-react';

interface LoggedAction {
  event_id: string;
  tenant: string;
  table_name: string;
  action_time: string;
  action_type: string;
  username: string;
  transaction_id: number;
  client_query: string | null;
}

interface RecentLoggedActionsTableProps {
  actions: LoggedAction[];
  isLoading: boolean;
}

export const RecentLoggedActionsTable: React.FC<RecentLoggedActionsTableProps> = ({ actions, isLoading }) => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const toggleRow = (eventId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(eventId)) {
        newSet.delete(eventId);
      } else {
        newSet.add(eventId);
      }
      return newSet;
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (actions.length === 0) {
    return <p>No recent logged actions available.</p>;
  }

  return (
    <div className="h-full overflow-y-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[30px]"></TableHead>
            <TableHead>Event ID</TableHead>
            <TableHead>Tenant</TableHead>
            <TableHead>Table Name</TableHead>
            <TableHead>Action Time</TableHead>
            <TableHead>Action Type</TableHead>
            <TableHead>Username</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {actions.map((action) => (
            <React.Fragment key={action.event_id}>
              <TableRow>
                <TableCell className="p-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => toggleRow(action.event_id)}
                    className="h-6 w-6 p-0"
                  >
                    {expandedRows.has(action.event_id) ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                </TableCell>
                <TableCell className="p-2">{action.event_id}</TableCell>
                <TableCell className="p-2">{action.tenant}</TableCell>
                <TableCell className="p-2">{action.table_name}</TableCell>
                <TableCell className="p-2">{new Date(action.action_time).toLocaleString()}</TableCell>
                <TableCell className="p-2">{action.action_type}</TableCell>
                <TableCell className="p-2">{action.username}</TableCell>
              </TableRow>
              {expandedRows.has(action.event_id) && (
                <TableRow>
                  <TableCell colSpan={7} className="p-2">
                    <div className="p-2 bg-gray-50 rounded-md text-sm">
                      <p><strong>Transaction ID:</strong> {action.transaction_id}</p>
                      <p><strong>Client Query:</strong></p>
                      {action.client_query ? (
                        <pre className="text-xs overflow-x-auto">{action.client_query}</pre>
                      ) : (
                        <p className="text-xs text-gray-500 italic">No query available</p>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
