import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Link } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { AlertCircle, Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import apiClient from '../api/client';
import * as Tooltip from '@radix-ui/react-tooltip';

interface User {
  email: string;
  account_name: string;
  created_at: string;
  updated_at: string;
}

interface UsersListProps {
  users: User[];
  accountName: string;
  onUserAdded: (newUser: User) => void;
}

export const UsersList: React.FC<UsersListProps> = ({ users, accountName, onUserAdded }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMultiOpen, setIsMultiOpen] = useState(false);
  const [multiEmails, setMultiEmails] = useState('');
  const [multiError, setMultiError] = useState<string | null>(null);

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await apiClient.put(
        `/admin/users/create?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&account_name=${encodeURIComponent(accountName)}`
      );
      onUserAdded(response.data);
      setIsOpen(false);
      setEmail('');
      setPassword('');
    } catch (error: any) {
      console.error('Failed to create user:', error);
      setError(`Failed to create user: ${error.response?.data?.detail || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateMultipleUsers = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMultiError(null);

    const emails = multiEmails
      .split(/[\s,;]+/)  // Split on whitespace, commas, or semicolons
      .map(email => email.trim())  // Trim whitespace
      .filter(email => email.includes('@'))  // Basic email validation
      .filter((email, index, self) => self.indexOf(email) === index);  // Remove duplicates

    if (emails.length === 0) {
      setMultiError('No valid email addresses found');
      setIsLoading(false);
      return;
    }

    try {
      const response = await apiClient.post('/admin/users/create_multiple', { emails, account_name: accountName });

      if (response.status === 207) {
        const { created_users, errors } = response.data.detail;
        created_users.forEach(onUserAdded);
        if (errors && errors.length > 0) {
          setMultiError(`Some users were not created: ${errors.join(', ')}`);
        } else {
          setIsMultiOpen(false);
          setMultiEmails('');
        }
      } else {
        // Handle successful creation of all users
        response.data.forEach(onUserAdded);
        setIsMultiOpen(false);
        setMultiEmails('');
      }
    } catch (error: any) {
      let errorMessage = 'Failed to create users: ';
      if (error.response?.data?.detail) {
        if (typeof error.response.data.detail === 'object' && error.response.data.detail.errors) {
          errorMessage += error.response.data.detail.errors.join(', ');
        } else if (Array.isArray(error.response.data.detail)) {
          errorMessage += error.response.data.detail.join(', ');
        } else if (typeof error.response.data.detail === 'string') {
          errorMessage += error.response.data.detail;
        } else {
          errorMessage += JSON.stringify(error.response.data.detail);
        }
      } else if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += 'Unknown error occurred';
      }
      setMultiError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Users</h3>
        <div className="flex space-x-2">
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <Button>Create User</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create New User</DialogTitle>
              </DialogHeader>
              <form onSubmit={handleCreateUser}>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="email" className="text-right">
                      Email
                    </Label>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="col-span-3"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="password" className="text-right">
                      Password
                    </Label>
                    <Tooltip.Provider>
                      <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                          <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="col-span-3"
                          />
                        </Tooltip.Trigger>
                        <Tooltip.Content side="right" align="center" className="bg-gray-700 text-white p-2 rounded">
                          Leave blank to auto-generate a password.
                        </Tooltip.Content>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </div>
                </div>
                {error && (
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
                <div className="flex justify-end">
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Creating...
                      </>
                    ) : (
                      'Create User'
                    )}
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog open={isMultiOpen} onOpenChange={setIsMultiOpen}>
            <DialogTrigger asChild>
              <Button>Add Multiple Users</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Multiple Users</DialogTitle>
              </DialogHeader>
              <form onSubmit={handleCreateMultipleUsers}>
                <div className="grid gap-4 py-4">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="multiEmails" className="text-right">
                      Emails
                    </Label>
                    <textarea
                      id="multiEmails"
                      value={multiEmails}
                      onChange={(e) => setMultiEmails(e.target.value)}
                      className="col-span-3"
                      required
                    />
                  </div>
                </div>
                {multiError && (
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>{multiError}</AlertDescription>
                  </Alert>
                )}
                <div className="flex justify-end">
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Creating...
                      </>
                    ) : (
                      'Create Users'
                    )}
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      {users.length === 0 ? (
        <p>No users available.</p>
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>Updated At</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.email} className="hover:bg-muted/50 transition-colors">
                <TableCell>
                  <Link to="/users/$email" params={{ email: user.email }} className="text-primary hover:underline">
                    {user.email}
                  </Link>
                </TableCell>
                <TableCell>{new Date(user.created_at).toLocaleString()}</TableCell>
                <TableCell>{new Date(user.updated_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
