import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import apiClient from '../api/client';
import { useDescriptions } from './hooks/useDescriptions';

interface ValidEvent {
  event: string;
  account_type: string;
  from_state: string;
  to_state: string;
  from_state_description: string;
  to_state_description: string;
  event_description: string;
}

interface AccountStateChangeProps {
  accountName: string;
  currentState: string;
  onStateChange: () => void;
  onValidEventsReceived: (events: ValidEvent[]) => void;
}

export const AccountStateChange: React.FC<AccountStateChangeProps> = ({ accountName, currentState, onStateChange, onValidEventsReceived }) => {
  const [validEvents, setValidEvents] = useState<ValidEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { toast } = useToast();
  const descriptions = useDescriptions();

  useEffect(() => {
    const fetchValidEvents = async () => {
      try {
        const response = await apiClient.get(`/admin/accounts/${accountName}/valid_events`);
        setValidEvents(response.data);
        onValidEventsReceived(response.data);
      } catch (error) {
        console.error('Failed to fetch valid events:', error);
        setError('Failed to load valid events. Please try again.');
      }
    };

    fetchValidEvents();
  }, [accountName]);

  const handleStateChange = async () => {
    if (!selectedEvent) return;

    setIsLoading(true);
    setError(null);

    try {
      await apiClient.post(`/admin/accounts/${accountName}/send_event/${selectedEvent}`);
      toast({
        title: "State Changed",
        description: `Account state successfully changed to ${validEvents.find(event => event.event === selectedEvent)?.to_state}`,
      });
      onStateChange();
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Failed to change account state:', error);
      setError('Failed to change account state. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (validEvents.length === 0) {
    return null;
  }

  return (
    <div className="mt-4">
      <div className="flex items-center space-x-4">
        <Select onValueChange={(value) => setSelectedEvent(value)}>
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Select event" />
          </SelectTrigger>
          <SelectContent>
            {validEvents
              .filter((event) => event.to_state !== currentState)
              .map((event) => (
                <SelectItem key={event.event} value={event.event}>
                  {event.event} (to {event.to_state})
                  {descriptions?.accountStateEvents[event.event] && (
                    <span className="text-sm text-gray-500 ml-2">
                      - {descriptions.accountStateEvents[event.event]}
                    </span>
                  )}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button disabled={!selectedEvent}>Change State</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm State Change</DialogTitle>
              <DialogDescription>
                Are you sure you want to change the account state from {currentState} to {validEvents.find(event => event.event === selectedEvent)?.to_state}?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleStateChange} disabled={isLoading}>
                {isLoading ? 'Changing...' : 'Confirm'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {error && (
        <Alert variant="destructive" className="mt-4">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};
