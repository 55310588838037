import React, { useMemo } from 'react';
import { Link } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { getBaseURL } from '../api/client'; // Import the getBaseURL function

interface NavProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const Navigation: React.FC<NavProps> = ({ isLoggedIn, onLogout }) => {
  const environment = useMemo(() => {
    const baseURL = getBaseURL();
    if (baseURL.includes('localhost') || baseURL.includes('127.0.0.1')) return 'local';
    if (baseURL.includes('.dev')) return 'dev';
    if (baseURL.includes('.xyz')) return 'demo';
    if (baseURL.includes('.com')) return 'prod';
    return 'unknown';
  }, []);

  const getEnvironmentColor = () => {
    switch (environment) {
      case 'local': return '#3B82F6'; // Blue
      case 'dev': return '#10B981';   // Green
      case 'demo': return '#F59E0B';  // Yellow
      case 'prod': return '#EF4444';  // Red
      default: return '#6B7280';      // Gray
    }
  };

  return (
    <nav className="bg-primary text-primary-foreground shadow-md relative">
      <div style={{ height: '4px', width: '100%', backgroundColor: getEnvironmentColor() }} className="absolute top-0 left-0"></div>
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex space-x-6">
          <Link to="/" className="font-semibold hover:text-primary-foreground/80 transition-colors">Dashboard</Link>
          <Link to="/accounts" className="font-semibold hover:text-primary-foreground/80 transition-colors">Accounts</Link>
          <Link to="/users" className="font-semibold hover:text-primary-foreground/80 transition-colors">Users</Link>
        </div>
        <div className="flex items-center">
          <span style={{ color: getEnvironmentColor() }} className="mr-4 text-sm font-medium">
            {environment.toUpperCase()} Environment
          </span>
          {isLoggedIn ? (
            <Button onClick={onLogout} variant="ghost" className="hover:text-primary-foreground/80 transition-colors">Logout</Button>
          ) : (
            <Button asChild variant="ghost" className="hover:text-primary-foreground/80 transition-colors">
              <Link to="/login">Login</Link>
            </Button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
