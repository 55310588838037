import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from '@tanstack/react-router';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import apiClient from '../api/client';
import { User, Key, Trash2 } from 'lucide-react';

interface UserData {
  email: string;
  account_name: string;
  created_at: string;
  updated_at: string;
}

const UserDetails: React.FC = () => {
  const { email } = useParams({ from: '/users/$email' });
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClient.get(`/admin/users/${encodeURIComponent(email)}`);
        setUser(response.data);
      } catch (error: any) {
        console.error('Failed to fetch user:', error);
        setError(`Failed to load user: ${error.response?.data?.detail || error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [email]);

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newPassword) return;

    try {
      await apiClient.put(`/admin/users/${encodeURIComponent(email)}/change_password?password=${encodeURIComponent(newPassword)}`);
      toast({
        title: "Success",
        description: "Password changed successfully",
      });
      setIsChangePasswordModalOpen(false);
      setNewPassword('');
    } catch (error: any) {
      console.error('Failed to change password:', error);
      const errorMessage = error.response?.data?.detail?.[0]?.msg || error.response?.data?.detail || error.message;
      toast({
        title: "Error",
        description: `Failed to change password: ${errorMessage}`,
        variant: "destructive",
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      await apiClient.delete(`/admin/users/${encodeURIComponent(email)}`);
      toast({
        title: "Success",
        description: "User deleted successfully",
      });
      setIsDeleteUserModalOpen(false);

      // Redirect to the account details page if available, otherwise to the accounts list
      if (user && user.account_name) {
        navigate({ to: '/accounts/$accountName', params: { accountName: user.account_name } });
      } else {
        navigate({ to: '/accounts' });
      }
    } catch (error: any) {
      console.error('Failed to delete user:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      toast({
        title: "Error",
        description: `Failed to delete user: ${errorMessage}`,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-6">User Details</h1>

      {loading ? (
        <Skeleton className="w-full h-24" />
      ) : error ? (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : user ? (
        <div className="grid gap-6 md:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <User className="mr-2 h-5 w-5" />
                User Information
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Account Name:</strong> {user.account_name}</p>
                <p><strong>Created At:</strong> {new Date(user.created_at).toLocaleString()}</p>
                <p><strong>Updated At:</strong> {new Date(user.updated_at).toLocaleString()}</p>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Actions</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <Dialog open={isChangePasswordModalOpen} onOpenChange={setIsChangePasswordModalOpen}>
                  <DialogTrigger asChild>
                    <Button className="w-full">
                      <Key className="mr-2 h-4 w-4" />
                      Change Password
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Change Password</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleChangePassword} className="space-y-4">
                      <div>
                        <Label htmlFor="newPassword">New Password</Label>
                        <Input
                          id="newPassword"
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </div>
                      <Button type="submit">Change Password</Button>
                    </form>
                  </DialogContent>
                </Dialog>

                <Dialog open={isDeleteUserModalOpen} onOpenChange={setIsDeleteUserModalOpen}>
                  <DialogTrigger asChild>
                    <Button variant="destructive" className="w-full">
                      <Trash2 className="mr-2 h-4 w-4" />
                      Delete User
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Delete User</DialogTitle>
                      <DialogDescription>
                        Are you sure you want to delete this user? This action cannot be undone.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                      <Button variant="outline" onClick={() => setIsDeleteUserModalOpen(false)}>Cancel</Button>
                      <Button variant="destructive" onClick={handleDeleteUser}>Delete</Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Alert>
          <AlertTitle>No Data</AlertTitle>
          <AlertDescription>No user data available.</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default UserDetails;
